import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.css']
})
export class DeleteConfirmationDialogComponent implements OnInit {

  context: string;

  deletionForm: FormGroup = new FormGroup({
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.context = this.data.context;
  }

  ngOnInit(): void {
  }

}
