import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { noWhitespace } from '../../validators/no-whitespace.validator';

@Component({
  selector: 'app-add-text-card-dialog',
  templateUrl: './add-text-card-dialog.component.html',
  styleUrls: ['./add-text-card-dialog.component.css']
})
export class AddTextCardDialogComponent implements OnInit {

  cardForm: FormGroup = new FormGroup({
    cardText: new FormControl('', [Validators.required, noWhitespace]),
  });

  constructor() { }

  ngOnInit(): void {
  }

}
