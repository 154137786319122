<h1 mat-dialog-title>Create Section</h1>
<div mat-dialog-content [formGroup]="sectionForm" class="modal-content">
    <mat-form-field>
        <mat-label>Section Name</mat-label>
        <input matInput type="text" placeholder="Section Name" formControlName="sectionName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Select Section Type</mat-label>
      <mat-select formControlName="sectionType">
        <mat-option [value]="type" *ngFor="let type of sectionTypes">{{type}}</mat-option>
      </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="sectionForm.value" [disabled]="!sectionForm.valid">Create</button>
</div>