import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Comment } from '../interfaces/comment.interface';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  @Input() comment: Comment;
  @Input() index: number;
  @Input() showGifs: boolean;
  @Input() editable: boolean;
  @Output() deleteEvent = new EventEmitter<Comment>();
  @Output() editEvent = new EventEmitter<Comment>();
  editMode = false;
  commentForm: FormGroup = new FormGroup({
    comment: new FormControl('')
  });

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.commentForm.controls.comment.setValue(this.comment.content);
  }

  getCommentColor(): string {
    const colorIndex = (this.index + 1) % 4;
    let color;

    switch (colorIndex) {
      case 1: {
        color = 'comment-blue';
        break;
      }
      case 2: {
        color = 'comment-green';
        break;
      }
      case 3: {
        color = 'comment-yellow';
        break;
      }
      default: {
        color = 'comment-red';
        break;
      }
    }

    return color;
  }

  getCommentColorGif(): string {
    let color = this.getCommentColor();
    if (this.showGifs) {
      return color += ' gif-comment';
    }
    return color;
  }

  toggleEdit(): void {
    this.editMode = !this.editMode;
  }

  deleteComment(): void {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { context: 'comment'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteEvent.emit(this.comment);
      }
    });
  }

  onSubmit(value): void {
    if (!(value.comment === '')) {
      this.comment.content = value.comment;
    }
    this.editEvent.emit(this.comment);
    this.editMode = false;
  }

}
