import {Component, Input, OnInit} from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { DeleteConfirmationDialogComponent } from '../../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { EditBoardDialogComponent } from '../../navigator/edit-board-dialog/edit-board-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Board } from '../../interfaces/board.interface';

@Component({
  selector: 'app-collection-board',
  templateUrl: 'collection-board.component.html',
  styleUrls: ['collection-board.component.css']
})

export class CollectionBoardComponent implements OnInit{
	@Input() boardName: String;
	@Input() boardId: String;

	constructor(private dialog: MatDialog, private firebaseService: FirebaseService) {
	}

	ngOnInit(): void {

	}

	editBoard(): void {
		event.stopPropagation();
		const dialogRef = this.dialog.open(EditBoardDialogComponent, {
			data: { currentBoard: this.boardName, context: 'Board' }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.firebaseService.updateBoard({id: this.boardId, name: result.boardName} as Board);
			}
		});
	}

	deleteBoard(): void {
		event.stopPropagation();
		const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
			data: { context: 'board' }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.firebaseService.deleteBoardFromNav({id: this.boardId} as Board);
			}
		});
	}
}