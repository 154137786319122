import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FirebaseService } from '../services/firebase.service';
import { Card } from '../interfaces/card.interface';
import { Section, SectionType, NominationType } from '../interfaces/section.interface'
import { Vote } from '../interfaces/vote.interface';
import { MatDialog } from '@angular/material/dialog';
import { AddTextCardDialogComponent } from './add-text-card-dialog/add-text-card-dialog.component';
import { AddActionCardDialogComponent } from './add-action-card-dialog/add-action-card-dialog.component';
import { AddNominationCardDialogComponent } from './add-nomination-card-dialog/add-nomination-card-dialog.component';
import { EditSectionDialogComponent } from './edit-section-dialog/edit-section-dialog.component';
import { Nomination } from '../interfaces/nomination.interface';
import { AddPollCardDialogComponent } from './add-poll-card-dialog/add-poll-card-dialog.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-section',
	templateUrl: './section.component.html',
	styleUrls: ['./section.component.css']
})

export class SectionComponent implements OnInit {
	@Input() section: Section;
	@Input() sectionConnections: [];
	@Input() headerColor: String;
	@Input() cards: Card[];
	@Input() comments: Record<string, Comment[]> = {};
	@Input() votes: Record<string, Vote[]> = {};
	sectionType: any = SectionType;

	@ViewChild(MatMenuTrigger)
  	contextMenu: MatMenuTrigger;
  	contextMenuPosition = { x: "0px", y: "0px" };

	constructor(
		public firebaseService: FirebaseService,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
		) { }

	ngOnInit(): void {
	}

	dropCard(event: CdkDragDrop<string[]>): void {
		if	(event.previousContainer === event.container) {
			this.firebaseService.moveCardInArray(event.container.data, event.previousIndex, event.currentIndex);
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			this.firebaseService.transferCardToSection(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex,
				event.container.id);
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}
	}

	handleAddCard(): void {
		switch(this.section.type) {
			case SectionType.Standard:
				this.openTextDialog();
				break;
			case SectionType.ActionItem:
				this.openActionDialog();
				break;
			case SectionType.Nomination:
				this.openNominationDialog();
				break;
		}
	}

	openTextDialog(): void {
		this.dialog.open(AddTextCardDialogComponent)
		.afterClosed().subscribe(result => {
			this.firebaseService.putCard({
				id: null,
				userId: null,
				sectionId: this.section.id,
				text: result.cardText,
				position: this.cards.length,
				completed: false
			});
		});
	}

	openActionDialog(): void {
		this.dialog.open(AddActionCardDialogComponent)
		.afterClosed().subscribe(result => {
			this.firebaseService.putCard({
				id: null,
				userId: null,
				sectionId: this.section.id,
				text: result.cardAction,
				position: this.cards.length,
				completed: false
			});
		});
	}

	openNominationDialog(): void {
		this.dialog.open(AddNominationCardDialogComponent)
		.afterClosed().subscribe(result => {
			this.firebaseService.putNomination({
				id: null,
				userId: null,
				sectionId: this.section.id,
				text: result.cardAction,
				position: this.cards.length,
				type: result.nominationType
			});
		});
	}

	openEditModal(): void {
		this.dialog.open(EditSectionDialogComponent)
		.afterClosed().subscribe(result => {
			if (result) {
				if (result.sectionName) {
					this.section.title = result.sectionName;
					this.firebaseService.updateSectionTitle(this.section);
				}
				if (result.deleteSection) {
					const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
						data: { context: 'section'}
					  });
				  
					  dialogRef.afterClosed().subscribe(result => {
						if (result) {
							this.firebaseService.deleteSection(this.section);
						}
					  });
				}
			}
		});
	}

	onContextMenu(event: MouseEvent) {
		event.preventDefault();
		if (this.section.type != SectionType.Nomination) {
			return;
		}
		this.contextMenuPosition.x = event.clientX + "px";
		this.contextMenuPosition.y = event.clientY + "px";
		this.contextMenu.menu.focusFirstItem("mouse");
		this.contextMenu.openMenu();
	 }
  
	 onContextMenuCopy() {
		this.openSnackBar('Section copied to clipboard', 'Dismiss');
	 }

	 getFormattedKudos(): String {
		if (this.cards.length == 0) {
			return;
		}
		let kudosString = '';
		this.cards.forEach(card => {
			let cast = card as unknown;
			let nom = cast as Nomination;
			switch (nom.type) {
				case NominationType.Craftsmanship:
					kudosString += ':craftsmanship-sticker:';
					break;
				 case NominationType.Courage:
					kudosString += ':courage-sticker:';
					break;
				 case NominationType.Community:
					kudosString += ':community-sticker:';
					break;
				 default:
					kudosString += ':fun-sticker:';
			}
			kudosString += ' ';
			kudosString += nom.text;
			kudosString += '\n';
		});
		kudosString.slice(0, -1);
		return kudosString;
	 }

	 openSnackBar(message: string, action: string): void {
		this.snackBar.open(message, action, {
		  duration: 2000,
		});
	 }
}