import {Component, Input, OnInit} from '@angular/core';
import {Collection} from '../interfaces/collection.interface';
import {Board} from '../interfaces/board.interface';
import {FirebaseService} from '../services/firebase.service';
import { Card } from '../interfaces/card.interface';

@Component({
  selector: 'app-profile-action-items',
  templateUrl: 'profile-action-items.component.html',
  styleUrls: ['profile-action-items.component.css']
})

export class ProfileActionItemsComponent implements OnInit{
  @Input() collection: Collection;
  hasCollections: boolean;
  hasBoards: boolean;
  collections: Collection[];
  boards: Board[];
  actionItems: Board[];
  panelOpenState = false;

  constructor(public firebaseService: FirebaseService) {
  }

  ngOnInit(): void {
    this.hasCollections = false;
    this.hasBoards = false;
    this.getBoards();
    this.getCollections();
  }

  getBoards(): void {
    this.boards = [];
    this.firebaseService.watchBoardsInCollection(this.collection, (boards) => {
      if (boards.length !== 0) {
        boards.forEach(board => {
          this.boards.push(board);
          this.getCompletedActionItems(board);
        });
      }
    });
  }

  getCollections(): void {
    this.firebaseService.watchCollectionsInCollection(this.collection, (collections) => {
      if (collections.length !== 0) {
        this.collections = collections;
        this.hasCollections = true;
      }
    });
  }

  getCompletedActionItems(board: Board): void {
    this.firebaseService.watchPastActionItems(board, true, (actionItems) => {
      this.actionItems = actionItems;
    });
  }
}
