import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';
import { FirebaseService } from '../services/firebase.service';
import {Collection} from '../interfaces/collection.interface';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from "firebase/app";
import 'firebase/auth';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { AddCollectionDialogComponent } from './add-collection-dialog/add-collection-dialog.component';
import { Template } from '../interfaces/template.interface';
import { Participation } from '../interfaces/participation.interface';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  showGifs = window.localStorage.getItem('showGifs') === 'true';
  userCollections: Collection[];
  allUserCollections: Collection[];
  allUserTemplates: Template[];
  userRecentBoards: Participation[];
  public userEmail: string;
  mostRecentId = null;

  constructor(
    private dialog: MatDialog,
    public firebaseService: FirebaseService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) { }

  async ngOnInit(): Promise<void> {
    this.firebaseService.watchCollectionsInUser((collections) => {
      this.allUserCollections = collections;
      let tempCollections = [];
      for (const collection of collections) {
        if (collection.parentCollectionId === '') {
          tempCollections.push(collection);
        }
      }
      this.userCollections = tempCollections;
    });

    this.firebaseService.watchTemplates((templates) => {
      this.allUserTemplates = templates;
    });

    this.firebaseService.watchRecentBoards((boards) => {
      this.userRecentBoards = boards;
    });

    this.userEmail = this.firebaseService.getUserEmail();
  }

  toggleGifs(): void {
    window.localStorage.setItem('showGifs', String(!this.showGifs));
  }

  handleCreateCollection(): void {
  const dialogRef = this.dialog.open(AddCollectionDialogComponent, {
    data:
      {
        collectionsList: this.allUserCollections
      }});
	dialogRef.afterClosed().subscribe(result => {
		if (result) {
			this.firebaseService.putCollection({
				userId: this.firebaseService.getUserId(),
				id: null,
				name: result.collectionName,
				parentCollectionId: ""
			},
			(id) => {
        this.mostRecentId = id;
      });
		}
	});
}

  handleDeleteAccountButton(): void {
    const dialogRef = this.dialog.open(DeleteAccountComponent);

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const currentUser = await firebase.auth().currentUser;

        currentUser.delete().then(() => {
          firebase.auth().signOut().then(() => {
            this.firebaseService.clearLocalStorage();
          }).catch((error) => {
            alert('Error: ' + error);
          });
        }).catch((error) => {
          alert('Error: ' + error);
        });
      }
    });
  }

  linkToBoard(boardId: string): void {
    this.router.navigate(['board', {id: boardId}], { relativeTo: this.route });
  }
}
