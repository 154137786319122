import { Component, OnInit, Input } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { Section } from '../interfaces/section.interface'
import { Board } from '../interfaces/board.interface';
import { MatDialog } from '@angular/material/dialog';
import { EditSectionDialogComponent } from '../section/edit-section-dialog/edit-section-dialog.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-section-past-action-items',
  templateUrl: './section-past-action-items.component.html',
  styleUrls: ['./section-past-action-items.component.css']
})
export class SectionPastActionItemsComponent implements OnInit {
  @Input() boards: Board;
  @Input() section: Section;
  @Input() headerColor: string;

  constructor(public firebaseService: FirebaseService, public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  completedChanged(event, card, boardIndex, cardIndex): void {
    this.firebaseService.updateIsCompleted(card.id, event.checked);
  }

  openEditModal(): void {
		this.dialog.open(EditSectionDialogComponent)
		.afterClosed().subscribe(result => {
			if (result) {
				if (result.sectionName) {
					this.section.title = result.sectionName;
					this.firebaseService.updateSectionTitle(this.section);
				}
				if (result.deleteSection) {
					const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
						data: { context: 'section'}
					  });
				  
					dialogRef.afterClosed().subscribe(deleteResult => {
						if (deleteResult) {
							this.firebaseService.deleteSection(this.section);
						}
					  });
				}
			}
		});
	}

	onContextMenu(event: MouseEvent) {
		event.preventDefault();
	}
}
