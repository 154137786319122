<mat-card [routerLink]="['board', {id: boardId}]" class="clickable">
	<mat-panel-title class="spaced">
		<div>
			{{boardName}}
		</div>
		<div>
			<button mat-button aria-label="Delete Board" color="warn" alt="delete board" (click)="deleteBoard()">
				<mat-icon> delete </mat-icon>
			</button>
			<button mat-button aria-label="Edit Board" color="basic" alt="edit board" (click)="editBoard()">
				<mat-icon> edit </mat-icon>
			</button>
		</div>
	</mat-panel-title>
</mat-card>