<mat-tab-group [selectedIndex]="0">
	<mat-tab label="My Boards">
		<mat-card id="boardsCard">
			<mat-card-header id="boardHeadBoards" class="boardHead">
				<h2 id="boardTitle">My Boards</h2>
				<button mat-raised-button aria-label="Add Collection" color="primary" id="btn-add-collection" (click)="handleCreateCollection()" alt="add collection">
					<label>Add Collection </label>
					<mat-icon> library_add </mat-icon>
				</button>
			</mat-card-header>
			<mat-accordion>
				<app-collection *ngFor="let collection of userCollections;" [collection]="collection" [templates]="allUserTemplates" [shouldExpand]="collection.id === mostRecentId"></app-collection>
			</mat-accordion>
		</mat-card>
		<mat-card id="boardsCard">
			<mat-card-header id="boardHeadBoards" class="boardHead">
				<h2>Recently Viewed Boards</h2>
			</mat-card-header>
			<mat-card *ngFor="let board of userRecentBoards" (click)="linkToBoard(board.id)" class="recentBoard">
				<div class="spaced">
					<h3>{{board.name}}</h3>
					<h5><i>Visited: {{board.timestamp.seconds * 1000 | date:'short'}}</i></h5>
				</div>
			</mat-card>
		</mat-card>
	</mat-tab>
	<mat-tab label="My Action Items">
		<mat-card id="boardsCard">
			<mat-card-header id="boardHeadActionItems" class="boardHead">
				<h2 id="AITitle">My Completed Action Items</h2>
			</mat-card-header>
			<mat-accordion>
				<app-profile-action-items *ngFor="let collection of userCollections;" [collection]="collection"></app-profile-action-items>
			</mat-accordion>
		</mat-card>
	</mat-tab>
	<mat-tab label="Preferences">
		<mat-card id="infoCard">
			<mat-card-header id="boardHeadPreferences" class="boardHead">
				<h2 id="PreferencesTitle">Preferences</h2>
			</mat-card-header>
			<div class="infoContainer">
				<h3><mat-slide-toggle [(ngModel)]="showGifs" (click)="toggleGifs()">Show Gifs</mat-slide-toggle></h3>
				<h3>User Email: {{userEmail}}</h3>
				<button
					mat-raised-button color="warn"
					aria-label="Delete Account"
					(click)="handleDeleteAccountButton()"
					class="deleteAcct">
					Delete Account
				</button>
				<br/><br/>
			</div>
		</mat-card>
	</mat-tab>
	<mat-tab label="Support">
		<mat-card id="infoCard">
			<mat-card-header id="boardHeadSupport" class="boardHead">
				<h2 id="PreferencesTitle">Support</h2>
			</mat-card-header>
			<div>
				<a href="slack://channel?id=C01C8A935RB&team=T03L19X68" title="Open #nretrospect in Slack" class="imageLinkContainer">
					<img src="../../assets/images/Slack_Mark_Web.png" inline="true" id="icon" width="50" height="50"/>
					<h3>Join us on Slack</h3>
				</a>
				<a href="https://trello.com/b/OJP4Lzfm/nretro" class="imageLinkContainer">
					<img src="../../assets/images/trello-mark-circle.svg" inline="true" id="icon" width="50" height="50"/>
					<h3>View our tickets</h3>
				</a>
			</div>
		</mat-card>
		<mat-card id="infoCard">
			<mat-card-header id="boardHeadSupport" class="boardHead">
				<h2 id="PreferencesTitle">FAQ</h2>
			</mat-card-header>
			<div class="infoContainer">
				<h3>How to get started as a Scrum Master</h3>
				<h4>Navigate to the My Boards tab and create a collection. Collections are used to group retrospective boards, and often define teams or segments. To make your first board, select Add Board and fill out the required information, including the collection it will belong to. Add sections to suit your style and copy the board url to share with other nCino employees.</h4>
				<h3>What are board Templates?</h3>
				<h4>Templates allow you to be efficient when creating new boards by storing section layouts for reuse. To save a board layout as a template, click the chevron next to the title on the board screen and select Save as Template.</h4>
				<h3>How to edit or delete cards and sections</h3>
				<h4>Select the card or section header to bring up dialogues with options to delete and edit.</h4>
				<h3>How to delete a board</h3>
				<h4>The ability to delete a board was added in Dec 2020. Simply open the board and click the cheveron next to the name to view options for deleting and renaming boards.</h4>
				<br/>
			</div>
		</mat-card>
	</mat-tab>
</mat-tab-group>
