import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import firebase from "firebase/app";
import 'firebase/auth';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  hide: boolean = true;
  error: boolean = false;
  errorMessage: string = 'Login Error';

  constructor(
    public route: ActivatedRoute,
    public router: Router,
  ) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.router.navigate(['/auth'], { relativeTo: this.route });
      } else {
        this.router.navigate(['/login'], { relativeTo: this.route });
      }
    });
  }

  ngOnInit(): void { }

  onSubmit(): void {

    const email: string = this.loginForm.value.username;

    const domain: string = email.replace(/.*@/, '');

    if (domain !== 'ncino.com') {
      alert('please enter an @ncino email address');
      return;
    }

    firebase.auth().sendSignInLinkToEmail(email, environment.actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      alert('The email was succesfully sent. Check your email and click the link');
      // Save the email locally so you don't need to ask the user for it again
      window.localStorage.setItem('emailForSignIn', email);
    })
    .catch((error) => {
      alert(error);
    });

  }

}
