import { Component, OnInit, Input } from '@angular/core';
import { Card } from '../interfaces/card.interface';
import { Comment } from '../interfaces/comment.interface';
import { MatDialog } from '@angular/material/dialog';
import { CardStandardDialogComponent } from '../card-standard-dialog/card-standard-dialog.component';
import { Vote } from '../interfaces/vote.interface';
import { FirebaseService } from '../services/firebase.service';
import { Nomination } from '../interfaces/nomination.interface';
import { SectionType, NominationType } from '../interfaces/section.interface';


@Component({
  selector: 'app-card-nomination',
  templateUrl: './card-nomination.component.html',
  styleUrls: ['./card-nomination.component.css']
})
export class CardNominationComponent implements OnInit {

  @Input() card: Nomination;
  @Input() comments: Comment[];
  @Input() votes: Vote[];

  constructor(public dialog: MatDialog, public firebaseService: FirebaseService) {
  }

  ngOnInit(): void {
  }

  openCardModal(): void {
    this.dialog.open(CardStandardDialogComponent, {
      data: { card: this.card, comments: this.comments, votes: this.votes, icon: this.getIconPath() },
      width: '30rem'
    });
  }

  vote(): void {
    const newVote: Vote = {
      userId: this.firebaseService.getUserId(),
      cardId: this.card.id
    };
    if ((this.votes.filter(vote => vote.userId === newVote.userId)).length > 0) {
      this.firebaseService.deleteVote(newVote);
      this.votes.splice(this.votes.indexOf(newVote, 0), 1);
    } else {
      this.firebaseService.putVote(newVote);
      this.votes.push(newVote);
    }
  }

  /*
  * Deprecated
  */
  star(): void {}

  getIconPath(): string {
    switch (this.card.type) {
      case NominationType.Craftsmanship:
        return "../../assets/images/craftsmanship.png";
      case NominationType.Courage:
        return "../../assets/images/courage.png"
      case NominationType.Community:
        return "../../assets/images/community.png";
      default:
        return "../../assets/images/fun.png";
    }
  }

}
