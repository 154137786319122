import {Component, Input, OnInit} from '@angular/core';
import {Collection} from '../interfaces/collection.interface';
import {Board} from '../interfaces/board.interface';
import {FirebaseService} from '../services/firebase.service';
import { AddCollectionDialogComponent } from '../profile/add-collection-dialog/add-collection-dialog.component';
import { AddBoardDialogComponent } from '../profile/add-board-dialog/add-board-dialog.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { EditBoardDialogComponent } from '../navigator/edit-board-dialog/edit-board-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Template } from '../interfaces/template.interface';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-collection',
  templateUrl: 'collection.component.html',
  styleUrls: ['collection.component.css']
})

export class CollectionComponent implements OnInit{
	@Input() collection: Collection;
	@Input() templates: Template[];
	@Input() shouldExpand: Boolean;
	hasCollections: Boolean;
	hasBoards: Boolean;
	collections: Collection[];
	boards: Board[];
	panelOpenState = false;

	constructor(
		public firebaseService: FirebaseService,
		private dialog: MatDialog,
		private router: Router,
    	private route: ActivatedRoute
	) {
	}

	ngOnInit(): void {
		this.hasCollections = false;
		this.hasBoards = false;
		this.getBoards();
		this.getCollections();
	}

	getBoards() {
		this.firebaseService.watchBoardsInCollection(this.collection, (boards) => {
			this.boards = boards;
		})
	}

	getCollections() {
		this.firebaseService.watchCollectionsInCollection(this.collection, (collections) => {
			if (collections.length != 0) {
				this.collections = collections;
				this.hasCollections = true;
			}
		})
	}

	handleCreateSubcollection(): void {
		const dialogRef = this.dialog.open(AddCollectionDialogComponent);
			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					this.firebaseService.putCollection({
						userId: this.firebaseService.getUserId(),
						id: null,
						name: result.collectionName,
						parentCollectionId: this.collection.id
					},
					(id) => {});
				}
			});
	}

	handleCreateBoard(): void {
		const dialogRef = this.dialog.open(AddBoardDialogComponent, {
			data:
			{
				templatesList: this.templates
			}});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				if (result.templateName) {
					this.firebaseService.putBoardFromTemplate({
						userId: this.firebaseService.getUserId(),
						id: null,
						name: result.boardName,
						collectionId: this.collection.id
					},
					result.templateName,
					(boardId) => {
						this.router.navigate(['board', {id: boardId}], { relativeTo: this.route });
					});
				} else {
					this.firebaseService.putBoard({
						userId: this.firebaseService.getUserId(),
						id: null,
						name: result.boardName,
						collectionId: this.collection.id
					},
					(boardId) => {
						this.router.navigate(['board', {id: boardId}], { relativeTo: this.route });
					});
				}
			}
		});
	}

	handleEditCollection(): void {
		const dialogRef = this.dialog.open(EditBoardDialogComponent, {
			data: { currentBoard: this.collection.name, context: 'Collection' }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.firebaseService.updateCollection({id: this.collection.id, name: result.boardName} as Collection);
			}
		});
	}

	handleDeleteCollection(): void {
		const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
			data: { context: 'collection' }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.firebaseService.deleteCollection(this.collection);
			}
		})
	}
}