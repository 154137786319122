import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import firebase from "firebase/app";
import 'firebase/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Board } from '../interfaces/board.interface';
import { FirebaseService } from '../services/firebase.service';
import { Section } from '../interfaces/section.interface';
import { Template } from '../interfaces/template.interface';
import { AddTemplateDialogComponent } from './add-template-dialog/add-template-dialog.component';
import { EditBoardDialogComponent } from './edit-board-dialog/edit-board-dialog.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.css']
})
export class NavigatorComponent implements OnInit {

  uemail: string;

  boardName: string;
  boardId: string;
  sections: Section[];
  canEdit = false;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    public firebaseService: FirebaseService,
    public dialog: MatDialog
  ) {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        this.router.navigate(['/login'], { relativeTo: this.route });
      }
    });

  }

  async ngOnInit(): Promise<void> {

    this.uemail = this.formatEmailToName(this.firebaseService.getUserEmail());

    this.setBoardName();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.boardName = '';
        this.setBoardName();
      }
    });
  }

  setBoardName(): void {
    if (this.onBoardPage()) {
      this.route.firstChild.params.subscribe(params => {
        this.boardId = params.id;
        this.firebaseService.getBoard(this.boardId, (board?: Board) => {
          this.boardName = board.name;
          this.canEdit = this.firebaseService.getUserId() === board.userId;
        });
        this.firebaseService.watchSections(this.boardId, (sections?: Section[]) => {
          this.sections = sections;
        });
      });
    }
  }

  onLogout(): void {
    firebase.auth().signOut().then(() => {
      localStorage.removeItem('userUID');
      localStorage.removeItem('showGifs');
      localStorage.removeItem('userEmail');
    }).catch((error) => {
      alert('Error: ' + error);
    });
  }

  formatEmailToName(bemail: string): string {
    let nemail = bemail.replace(/@.*/, '');
    nemail = nemail.toLocaleUpperCase().replace('.', ' ');
    return nemail;
  }

  onBoardPage(): boolean {
    return this.router.url.includes('board');
  }

  getUrl(): string {
    return window.location.href;
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  saveTemplate(): void {
    const dialogRef = this.dialog.open(AddTemplateDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const template: Template = {
          id: '',
          userId: '',
          name: result.templateName,
          templateData: JSON.stringify({
            sections: this.sections
          })
        };
        this.firebaseService.putTemplate(template);
        this.openSnackBar('Successfully saved new template', 'dismiss');
      }
    });
  }

  renameBoard(): void {
    const dialogRef = this.dialog.open(EditBoardDialogComponent, {
      data: { currentBoard: this.boardName, context: 'Board' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebaseService.updateBoard({id: this.boardId, name: result.boardName} as Board);
        this.setBoardName();
        this.openSnackBar('Successfully renamed board', 'dismiss');
      }
    });
  }

  deleteBoard(): void {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { context: 'board' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebaseService.deleteBoardFromNav({id: this.boardId} as Board);
        this.router.navigate(['home']);
      }
    });
  }
}
