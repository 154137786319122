import { Component, Inject, OnInit } from '@angular/core';
import { Card } from '../interfaces/card.interface';
import { Comment } from '../interfaces/comment.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { noWhitespace } from '../validators/no-whitespace.validator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseService } from '../services/firebase.service';
import { Vote } from '../interfaces/vote.interface';
import { TenorSearchComponent } from '../tenor-search/tenor-search.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { Nomination, NominationType } from '../interfaces/nomination.interface';

@Component({
  selector: 'app-card-standard-dialog',
  templateUrl: './card-standard-dialog.component.html',
  styleUrls: ['./card-standard-dialog.component.css']
})
export class CardStandardDialogComponent implements OnInit {
  card: Card;
  comments: Comment[];
  votes: Vote[];
  icon: string;
  cardForm: FormGroup = new FormGroup({
    card: new FormControl('', [
      Validators.required,
      noWhitespace
    ])
  });
  commentForm: FormGroup = new FormGroup({
    comment: new FormControl('', [
      Validators.required,
      noWhitespace
    ])
  });
  editMode = false;
  showGifs: boolean;
  canEdit: boolean;

  constructor(
    public dialog: MatDialog,
    public firebaseService: FirebaseService,
    public dialogRef: MatDialogRef<CardStandardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.card = this.data.card;
      this.comments = this.data.comments;
      this.votes = this.data.votes;
      this.icon = this.data.icon;
      this.showGifs = window.localStorage.getItem('showGifs') === 'true';
      this.canEdit = this.firebaseService.getUserId() === this.card.userId;
    }

  ngOnInit(): void {
    this.cardForm.controls.card.setValue(this.card.text);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(value): void {
    const newComment: Comment = {
      id: null,
      userId: this.firebaseService.getUserId(),
      cardId: this.card.id,
      content: value.comment,
      gif: null
    };
    this.commentForm.reset();
    this.firebaseService.putComment(newComment);
    this.comments.push(newComment);
  }

  editCard(value): void {
    if (!(value.card === '')) {
      this.card.text = value.card;
    }
    if (Object.values(NominationType).includes(this.card['type'])) {
      this.firebaseService.updateNomination(this.card as unknown as Nomination);
    } else {
      this.firebaseService.updateCard(this.card);
    }
    this.editMode = false;
  }

  deleteCard(): void {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { context: 'card'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (Object.values(NominationType).includes(this.card['type'])) {
          this.firebaseService.deleteNomination(this.card as unknown as Nomination);
        } else {
          this.firebaseService.deleteCard(this.card);
        }
        this.onNoClick();
      }
    });
  }

  deleteComment(event): void {
    this.comments.splice(this.comments.indexOf(event, 0), 1);
    this.firebaseService.deleteComment(event);
  }

  editComment(event): void {
    this.firebaseService.updateComment(event);
  }

  toggleEdit(): void {
    this.editMode = !this.editMode;
  }

  vote(): void {
    const newVote: Vote = {
      userId: this.firebaseService.getUserId(),
      cardId: this.card.id
    };
    if ((this.votes.filter(vote => vote.userId === newVote.userId)).length > 0) {
      this.firebaseService.deleteVote(newVote);
      this.votes.splice(this.votes.indexOf(newVote, 0), 1);
    } else {
      this.firebaseService.putVote(newVote);
      this.votes.push(newVote);
    }
  }

  /*
  * Deprecated
  */
  star(): void {}

  openTenor(): void {
    const dialogRef = this.dialog.open(TenorSearchComponent, { width: '50rem'});

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const newComment: Comment = {
          id: '',
          userId: this.firebaseService.getUserId(),
          cardId: this.card.id,
          content: '',
          gif: result.media[0].gif.url
        };
        this.comments.push(newComment);
        this.firebaseService.putComment(newComment);
      }
    });
  }

  checkEdit(index: number): boolean {
    return this.comments[index].userId === this.firebaseService.getUserId();
  }

}
