import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { FirebaseService } from '../services/firebase.service';
import { Comment } from '../interfaces/comment.interface';
import { MatDialog } from '@angular/material/dialog';
import { AddSectionDialogComponent } from './add-section-dialog/add-section-dialog.component';
import { Section, SectionType } from '../interfaces/section.interface';
import { Vote } from '../interfaces/vote.interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Board } from '../interfaces/board.interface';
import { Nomination } from '../interfaces/nomination.interface';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {

  laneMap = {0: [], 1: [], 2: [], 3: []};
  sectionMap = {};
  pollMap = {};
  optionMap = {};
  entryMap = {};
  commentMap: Record<string, Comment[]> = {};
  voteMap: Record<string, Vote[]> = {};
  sectionConnectionMap = {};
  boardId: string;
  board: Board;
  laneColors =["#0092c7", "#85be40", "#f1b643", "#ba202e"];

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.boardId = params.id;
      this.firebaseService.getBoard(this.boardId, (board?: Board) => {
        this.board = board;
        this.setRecentBoard();
        this.firebaseService.watchSections(this.boardId, (sections) => {
          const tempLaneMap = {0: [], 1: [], 2: [], 3: []};
          for (const section of sections) {
            tempLaneMap[section.lane].push(section);

            // build out connection map between sections
            this.sectionConnectionMap[section.id] = [];
            for (const otherSection of sections) {
              if (otherSection.id !== section.id) {
                this.sectionConnectionMap[section.id].push(otherSection.id);
              }
            }
            if (section.type === SectionType.PastActionItem) {
              this.firebaseService.watchPastActionItems(this.board, false, boards => {
                this.sectionMap[section.id] = boards;
              });
            } else if (section.type === SectionType.Poll) {
              this.firebaseService.watchPolls(section.id, polls => {
                this.pollMap[section.id] = polls;
                polls.forEach(poll => {
                  if (this.optionMap[section.id] === undefined) {
                    this.optionMap[section.id] = {};
                    this.entryMap[section.id] = {};
                  }
                  this.firebaseService.getPollOptions(poll, options => {
                    this.optionMap[section.id][poll.id] = options;
                  });
                  this.firebaseService.watchPollEntries(poll, entryMap => {
                    this.entryMap[section.id][poll.id] = entryMap;
                  });
                });
              });
            } else if (section.type === SectionType.Nomination) {
              this.firebaseService.watchNominations(section.id, (nominations) => {
                this.sectionMap[section.id] = nominations;
                nominations.forEach( nomination => {
                  this.firebaseService.watchComments(nomination.id, comments => {
                    this.commentMap[nomination.id] = comments;
                  });
                  this.firebaseService.watchVotes(nomination.id, votes => {
                    this.voteMap[nomination.id] = votes;
                  });
                });
              });
            } else {
              // fetch cards and place into sections
              this.firebaseService.watchCards(section.id, (cards) => {
                this.sectionMap[section.id] = cards;
                cards.forEach( card => {
                  this.firebaseService.watchComments(card.id, comments => {
                    this.commentMap[card.id] = comments;
                  });
                  this.firebaseService.watchVotes(card.id, votes => {
                    this.voteMap[card.id] = votes;
                  });
                });
              });
            }
          }
          this.laneMap = tempLaneMap;
        });
      });
    });
  }

  laneIndexFrom(id: string): number {
    switch (id) {
      case 'lane0':
        return 0;
      case 'lane1':
        return 1;
      case 'lane2':
        return 2;
      default:
        return 3;
    }
  }

  dropSection(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      this.firebaseService.moveSectionInArray(event.container.data, event.previousIndex, event.currentIndex);
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.firebaseService.transferSectionToLane(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
        this.laneIndexFrom(event.container.id));
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  handleCreateSection(laneNumber: number): void {
    const dialogRef = this.dialog.open(AddSectionDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firebaseService.putSection(
          {
            id: null,
            boardId: this.boardId,
            title: result.sectionName,
            lane: laneNumber,
            position: this.laneMap[laneNumber],
            type: result.sectionType
          }
        );
      }
    });
  }

  setRecentBoard(): void {
    if (this.board.userId != this.firebaseService.getUserId()) {
      this.firebaseService.putRecentBoard(
        { id: null },
        { id: this.boardId, name: this.board.name }
      );
    }
  }
}
