<mat-toolbar class="header" >
  <mat-toolbar-row>
    <a routerLink="/home">
      <img class="image" src="assets/images/logoBlack.png" type="img/png">
    </a>
    <span class="fill-space"></span>
    <div *ngIf="onBoardPage()" id="board-name-row">
      <h2>{{boardName}}</h2>
      <mat-select>
        <mat-option
          value="savetemplate"
          [cdkCopyToClipboard]="getUrl()"
          (click)="openSnackBar('Link copied to clipboard', 'dismiss')">
          <mat-icon> content_copy </mat-icon>Copy to Clipboard
        </mat-option>
        <mat-option
          (click)="saveTemplate()"
          value="copylink">
          <mat-icon> file_copy </mat-icon>Save as Template
        </mat-option>
        <mat-option
          *ngIf="canEdit"
          (click)="renameBoard()"
          value="rename">
          <mat-icon> edit </mat-icon>Rename board
        </mat-option>
        <mat-option
          *ngIf="canEdit"
          (click)="deleteBoard()"
          value="delete"
          class="delete">
          <mat-icon> delete </mat-icon>Delete board
        </mat-option>
      </mat-select>
    </div>
    <span class="fill-space"></span>
    <div>
      <a mat-button routerLink="./">
        <mat-icon matPrefix>person</mat-icon>
          {{this.uemail}}
      </a>
      <a mat-button (click)="onLogout()">
        <mat-icon matPrefix>exit_to_app</mat-icon>
        Logout
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>