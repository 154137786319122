import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-template-dialog',
  templateUrl: './add-template-dialog.component.html',
  styleUrls: ['./add-template-dialog.component.css']
})
export class AddTemplateDialogComponent implements OnInit {

  templateForm: FormGroup = new FormGroup({
    templateName: new FormControl('', Validators.required)
  });

  constructor() {}

  ngOnInit(): void {
  }

}
