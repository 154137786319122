import { Injectable } from '@angular/core';
import { Collection } from '../interfaces/collection.interface';
import { Board } from '../interfaces/board.interface';
import { Section, SectionType } from '../interfaces/section.interface';
import { Card } from '../interfaces/card.interface';
import { Comment } from '../interfaces/comment.interface';
import { Vote } from '../interfaces/vote.interface';
import { Poll } from '../interfaces/poll.interface';
import { PollOption } from '../interfaces/pollOption.interface';
import { PollEntry } from '../interfaces/pollEntry.interface';
import { Nomination } from '../interfaces/nomination.interface';
import { environment } from '../../environments/environment';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { Template } from '../interfaces/template.interface';
import { Contributor } from '../interfaces/contributor.interface';
import { Participation } from '../interfaces/participation.interface';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  db: any;

  constructor() {
    firebase.initializeApp(environment.firebaseConfig);
    this.db = firebase.firestore();
    this.db.settings({ experimentalForceLongPolling: true });
  }

  collectionConverter = {
    toFirestore: (coll: Collection) => {
        return {
          name: coll.name,
          userId: this.getUserId(),
          timestamp: firebase.firestore.Timestamp.now(),
          parentCollectionId: coll.parentCollectionId
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        data.id = snapshot.id;
        return data as Collection;
    }
  };

  boardConverter = {
    toFirestore: (board: Board) => {
        return {
          name: board.name,
          userId: this.getUserId(),
          collectionId: board.collectionId,
          timestamp: firebase.firestore.Timestamp.now()
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        data.id = snapshot.id;
        return data as Board;
    }
  };

  sectionConverter = {
    toFirestore: (section: Section) => {
        return {
          boardId: section.boardId,
          title: section.title,
          position: section.position,
          lane: section.lane,
          type: section.type
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        data.id = snapshot.id;
        return data as Section;
    }
  };

  cardConverter = {
    toFirestore: (card: Card) => {
        return {
          userId: this.getUserId(),
          sectionId: card.sectionId,
          text: card.text,
          position: card.position,
          completed: card.completed
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        data.id = snapshot.id;
        return data as Card;
    }
  };

  commentConverter = {
    toFirestore: (comment: Comment) => {
        return {
          userId: this.getUserId(),
          cardId: comment.cardId,
          content: comment.content,
          timestamp: firebase.firestore.Timestamp.now(),
          gif: comment.gif
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        data.id = snapshot.id;
        return data as Comment;
    }
  };

  voteConverter = {
    toFirestore: (vote: Vote) => {
        return {
          userId: this.getUserId(),
          cardId: vote.cardId,
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return data as Vote;
    }
  };

  /*
  * Deprecated
  */
  starConverter = {};

  pollConverter = {
    toFirestore: (poll: Poll) => {
        return {
          sectionId: poll.sectionId,
          position: poll.position,
          options: poll.options,
          title: poll.title,
          userId: this.getUserId(),
          showVotes: poll.showVotes
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        data.id = snapshot.id;
        return data as Poll;
    }
  };

  pollOptionConverter = {
    toFirestore: (pollOption: PollOption) => {
        return {
          position: pollOption.position,
          title: pollOption.title
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        data.id = snapshot.id;
        return data as PollOption;
    }
  };

  pollEntryConverter = {
    toFirestore: (pollEntry: PollEntry) => {
        return {
          userId: this.getUserId(),
          optionId: pollEntry.optionId
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return data as PollEntry;
    }
  };

  nominationConverter = {
    toFirestore: (nomination: Nomination) => {
      return {
        userId: this.getUserId(),
        sectionId: nomination.sectionId,
        position: nomination.position,
        text: nomination.text,
        type: nomination.type,
      };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        data.id = snapshot.id;
        return data as Nomination;
    }
  };

  templateConverter = {
    toFirestore: (template: Template) => {
      return {
        userId: this.getUserId(),
        name: template.name,
        templateData: template.templateData
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      data.id = snapshot.id;
      return data as Template;
    }
  };

  contributorConverter = {
    toFirestore: (contributor: Contributor) => {
      return {
        id: this.getUserId()
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      data.id = snapshot.id;
      return data as Contributor;
    }
  };

  participationConverter = {
    toFirestore: (participation: Participation) => {
      return {
        id: participation.id,
        name: participation.name,
        timestamp: firebase.firestore.Timestamp.now()
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      data.id = snapshot.id;
      return data as Participation;
    }
  }

  getUserId(): string {
    return localStorage.getItem('userUID');
  }

  getUserEmail(): string {
    return localStorage.getItem('userEmail');
  }

  setUserEmail(email: string): void {
    localStorage.setItem('userEmail', email);
  }

  setUserId(userId: string): void {
    localStorage.setItem('userUID', userId);
  }

  userGifTagTrue(): void {
    window.localStorage.setItem('showGifs', 'true');
  }

  clearLocalStorage(): void {
    window.localStorage.clear();
  }

  putCollection(collection: Collection, callback: (id: string) => void): void {
    this.db.collection('collections').withConverter(this.collectionConverter).add(collection).then( doc => {
      callback(doc.id);
    });
  }

  putBoard(board: Board, callback: (id: string) => void): void {
    this.db.collection('boards').withConverter(this.boardConverter).add(board).then( doc => {
      callback(doc.id);
    });
  }

  putBoardFromTemplate(board: Board, template: Template, callback: (id: string) => void): void {
    this.putBoard(board, (boardId) => {
      JSON.parse(template.templateData).sections.forEach(section => {
        section.boardId = boardId;
        this.putSection(section);
      }),
      callback(boardId);
    });
  }

  putSection(section: Section): void {
    this.db.collection('sections').withConverter(this.sectionConverter).add(section);
  }

  putCard(card: Card): void {
    this.db.collection('cards').withConverter(this.cardConverter).add(card);
  }

  putComment(comment: Comment): void {
    this.db.collection('comments').withConverter(this.commentConverter).add(comment);
  }

  putVote(vote: Vote): void {
    this.db.collection('cards').doc(vote.cardId).collection('votes').doc(this.getUserId()).withConverter(this.voteConverter).set(vote);
  }

  /*
  * Deprecated
  */
  putStar(): void {}

  putPoll(poll: Poll, callback: () => void): void {
    const realSectionId = poll.sectionId;
    poll.sectionId = 'fake buffer id';
    this.db.collection('polls').withConverter(this.pollConverter).add(poll).then(doc => {
      const batch = this.db.batch();
      let position = 0;
      poll.options.forEach(title => {
        const option = {position: position++, title};
        const ref = this.db.collection('polls').doc(doc.id).collection('options').doc();
        batch.set(ref, option);
      });
      const pollRef = this.db.collection('polls').doc(doc.id);
      batch.update(pollRef, {sectionId: realSectionId});
      batch.commit().then(() => {
        callback();
      });
    });
  }

  putPollEntry(entry: PollEntry, poll: Poll): void {
    this.db.collection('polls').doc(poll.id).collection('entries').doc(this.getUserId())
    .withConverter(this.pollEntryConverter).set(entry);
  }

  putNomination(nomination: Nomination): void {
    this.db.collection('nominations').withConverter(this.nominationConverter).add(nomination);
  }

  putTemplate(template: Template): void {
    this.db.collection('templates').withConverter(this.templateConverter).add(template);
  }

  putRecentBoard(contributor: Contributor, participation: Participation): void {
    this.db.collection('contributors').doc(this.getUserId()).withConverter(this.contributorConverter).set(contributor).then(doc => {
      this.db.collection('contributors').doc(this.getUserId()).collection('participations').doc(participation.id)
        .withConverter(this.participationConverter).set(participation);
    });
  }

  deleteCollection(collection: Collection): void {
    this.db.collection('boards').where('collectionId', '==', collection.id).get().then(boardSnapshot => {
      boardSnapshot.array.forEach(boardDoc => {
        this.deleteBoard({id: boardDoc.id} as Board);
      });
    });
    this.db.collection('collections').doc(collection.id).delete();
  }

  deleteBoard(board: Board): void {
    this.db.collection('sections').where('boardId', '==', board.id).get().then(sectionSnapshot => {
      sectionSnapshot.array.forEach(sectionDoc => {
        this.deleteSection({id: sectionDoc.id} as Section);
      });
    });
    this.db.collection('collections').doc(board.id).delete();
  }

  deleteBoardFromNav(board: Board): void {
    this.db.collection('boards').doc(board.id).delete();
  }

  deleteSection(section: Section): void {
    this.db.collection('cards').where('sectionId', '==', section.id).get().then(cardSnapshot => {
      cardSnapshot.docs.forEach(cardDoc => {
        this.deleteCard({id: cardDoc.id} as Card);
      });
    });
    this.db.collection('sections').doc(section.id).delete();
  }

  deleteCard(card: Card): void {
    this.db.collection('cards').doc(card.id).collection('votes').get().then(voteSnapshot => {
      voteSnapshot.docs.forEach(voteDoc => {
        this.db.collection('cards').doc(card.id).collection('votes').doc(voteDoc.id).delete();
      });
    });
    this.db.collection('comments').where('cardId', '==', card.id).get().then(commentSnapshot => {
      commentSnapshot.docs.forEach(commentDoc => {
        this.db.collection('comments').doc(commentDoc.id).delete();
      });
    });
    this.db.collection('cards').doc(card.id).delete();
  }

  deleteNominations(nom: Nomination): void {
    this.db.collection('nominations').doc(nom.id).collection('votes').get().then(voteSnapshot => {
      voteSnapshot.docs.forEach(voteDoc => {
        this.db.collection('nominations').doc(nom.id).collection('votes').doc(voteDoc.id).delete();
      });
    });
    this.db.collection('comments').where('cardId', '==', nom.id).get().then(commentSnapshot => {
      commentSnapshot.docs.forEach(commentDoc => {
        this.db.collection('comments').doc(commentDoc.id).delete();
      });
    });
    this.db.collection('nominations').doc(nom.id).delete();
  }

  deleteComment(comment: Comment): void {
    this.db.collection('comments').doc(comment.id).delete();
  }

  deleteVote(vote: Vote): void {
    this.db.collection('cards').doc(vote.cardId).collection('votes').doc(vote.userId).delete();
  }

  /*
  * Deprecated
  */
  deleteStar(): void {}
  
  deletePoll(poll: Poll): void {
    this.db.collection('polls').doc(poll.id).delete();
    this.db.collection('polls').doc(poll.id).collection('options').get().then(optionSnapshot => {
      optionSnapshot.docs.forEach(optionDoc => {
        this.db.collection('polls').doc(poll.id).collection('options').doc(optionDoc.id).delete();
      });
    });
    this.db.collection('polls').doc(poll.id).collection('entries').get().then(entrySnapshot => {
      entrySnapshot.docs.forEach(entryDoc => {
        this.db.collection('polls').doc(poll.id).collection('entries').doc(entryDoc.id).delete();
      });
    });
  }

  deletePollEntry(poll: Poll): void {
    this.db.collection('polls').doc(poll.id).collection('entries').doc(this.getUserId()).delete();
  }

  deleteNomination(nomination: Nomination): void {
    this.db.collection('nominations').doc(nomination.id).delete();
  }

  watchCollectionsInUser(callback: (collections: Collection[]) => void): void {
    this.db.collection('collections').where('userId', '==', this.getUserId()).orderBy('timestamp', 'desc')
    .withConverter(this.collectionConverter).onSnapshot( querySnapshot => {
      callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  watchBoardsInCollection(collection: Collection, callback: (boards: Board[]) => void): void {
    this.db.collection('boards').where('collectionId', '==', collection.id).orderBy('timestamp', 'desc')
    .withConverter(this.boardConverter).onSnapshot( querySnapshot => {
      callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  watchCollectionsInCollection(collection: Collection, callback: (collections: Collection[]) => void): void {
    this.db.collection('collections').where('parentCollectionId', '==', collection.id).orderBy('timestamp', 'desc')
    .withConverter(this.boardConverter).onSnapshot( querySnapshot => {
      callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  watchTemplates(callback: (templates: Template[]) => void): void {
    this.db.collection('templates').where('userId', '==', this.getUserId())
    .withConverter(this.templateConverter).onSnapshot( querySnapshot => {
      callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  watchRecentBoards(callback: (participations: Participation[]) => void): void {
    this.db.collection('contributors').doc(this.getUserId()).collection('participations')
    .orderBy('timestamp', 'desc').limit(10)
    .withConverter(this.participationConverter).get().then(querySnapshot => {
      callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  getBoard(boardId: string, callback: (board?: Board) => void): void {
    this.db.collection('boards').doc(boardId)
    .withConverter(this.boardConverter).get().then(boardSnapshot => {
      callback(boardSnapshot.data());
    });
  }

  getBoards(callback: (boards: Board[]) => void): void {
    this.db.collection('boards').where('userId', '==', this.getUserId()).orderBy('timestamp', 'desc')
    .withConverter(this.boardConverter).get().then( querySnapshot => {
      callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  getPollOptions(poll: Poll, callback: (options: PollOption[]) => void): void {
    this.db.collection('polls').doc(poll.id).collection('options')
    .withConverter(this.pollOptionConverter).orderBy('position').get().then(optionSnapshot => {
      callback(optionSnapshot.docs.map(doc => doc.data()));
    });
  }

  // returns ordered list of board objects, containing lists of cards where isCompleted = false
  watchPastActionItems(board: Board, completed: boolean, callback: (actionItems: any) => void): void {
    this.db.collection('boards').where('timestamp', '<', board.timestamp).where('collectionId', '==', board.collectionId)
    .orderBy('timestamp').withConverter(this.boardConverter).get().then( boardSnapshot => {
      const boards = [];
      boardSnapshot.docs.forEach(boardDoc => {
        if (boardDoc.id === board.id) {
          return;
        }
        const boardIndex = boards.length;
        const boardData = boardDoc.data();
        boardData.cards = [];
        boards.push(boardData);
        this.db.collection('sections').where('boardId', '==', boardDoc.id).where('type', '==', SectionType.ActionItem)
        .withConverter(this.sectionConverter).get().then(sectionSnapshot => {
          if (sectionSnapshot.docs.length === 0) {
            callback(boards.filter(potentialBoard => potentialBoard.cards.length !== 0));
            return;
          }
          this.db.collection('cards').where('sectionId', '==', sectionSnapshot.docs[0].id)
          .where('completed', '==', completed).withConverter(this.cardConverter).onSnapshot(cardSnapshot => {
            boards[boardIndex].cards = [];
            cardSnapshot.docs.forEach(cardDoc => {
              boards[boardIndex].cards.push(cardDoc.data());
            });
            callback(boards.filter(potentialBoard => potentialBoard.cards.length !== 0));
          });
        });
      });
    });
  }

  updateIsCompleted(cardId: string, completed: boolean): void {
    this.db.collection('cards').doc(cardId).update({completed});
  }

  watchSections(boardId: string, callback: (sections: Section[]) => void): void {
    this.db.collection('sections').where('boardId', '==', boardId).orderBy('position')
    .withConverter(this.collectionConverter).onSnapshot(querySnapshot => {
        callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  watchCards(sectionId: string, callback: (cards: Card[]) => void): void {
    this.db.collection('cards').where('sectionId', '==', sectionId).orderBy('position')
    .withConverter(this.cardConverter).onSnapshot(querySnapshot => {
        callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  watchComments(cardId: string, callback: (comments: Comment[]) => void): void {
    this.db.collection('comments').where('cardId', '==', cardId).orderBy('timestamp')
    .withConverter(this.commentConverter).onSnapshot(querySnapshot => {
        callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  watchVotes(cardId: string, callback: (votes: Vote[]) => void): void {
    this.db.collection('cards').doc(cardId).collection('votes')
    .withConverter(this.voteConverter).onSnapshot(querySnapshot => {
        callback(querySnapshot.docs.map(doc => doc.data()));
    });
  }

  /*
  * Deprecated
  */
  watchStars(): void {}

  watchPolls(sectionId: string, callback: (polls: Poll[]) => void): void {
    this.db.collection('polls').where('sectionId', '==', sectionId).orderBy('position')
    .withConverter(this.pollConverter).onSnapshot(pollSnapshot => {
      callback(pollSnapshot.docs.map(doc => doc.data()));
    });
  }

  watchPollEntries(poll: Poll, callback: (entries: Record<string, PollEntry[]>) => void): void {
    this.db.collection('polls').doc(poll.id).collection('entries')
    .withConverter(this.pollEntryConverter).onSnapshot(entrySnapshot => {
      const entryMap: Record<string, PollEntry[]> = {};
      entrySnapshot.docs.forEach(entryDoc => {
        const entry = entryDoc.data();
        if (entryMap[entry.optionId] === undefined) {
          entryMap[entry.optionId] = [];
        }
        entryMap[entry.optionId].push(entry);
      });
      callback(entryMap);
    });
  }

  watchNominations(sectionId: string, callback: (nominations: Nomination[]) => void): void {
    this.db.collection('nominations').where('sectionId', '==', sectionId).orderBy('position')
    .withConverter(this.nominationConverter).onSnapshot(nomSnapshot => {
      callback(nomSnapshot.docs.map(doc => doc.data()));
    });
  }

  moveCardInArray(array: any[], prevIndex: number, nextIndex: number): void {
    const batch = this.db.batch();
    const minIndex = nextIndex < prevIndex ? nextIndex : prevIndex;
    const maxIndex = nextIndex < prevIndex ? prevIndex : nextIndex;
    for (let index = minIndex; index <= maxIndex; index++) {
      const card = array[index];
      const ref = this.db.collection('cards').doc(card.id);
      if (index === prevIndex) {
        batch.update(ref, {position: nextIndex});
        continue;
      }
      const newPos = card.position + (nextIndex < prevIndex ? 1 : -1);
      batch.update(ref, {position: newPos});
    }
    batch.commit();
  }

  moveSectionInArray(array: any[], prevIndex: number, nextIndex: number): void {
    const batch = this.db.batch();
    const minIndex = nextIndex < prevIndex ? nextIndex : prevIndex;
    const maxIndex = nextIndex < prevIndex ? prevIndex : nextIndex;
    for (let index = minIndex; index <= maxIndex; index++) {
      const section = array[index];
      const ref = this.db.collection('sections').doc(section.id);
      if (index === prevIndex) {
        batch.update(ref, {position: nextIndex});
        continue;
      }
      const newPos = section.position + (nextIndex < prevIndex ? 1 : -1);
      batch.update(ref, {position: newPos});
    }
    batch.commit();
  }

  transferCardToSection(prevArray: any[], nextArray: any[], prevIndex: number, nextIndex: number, nextSectionId: string): void {
    const batch = this.db.batch();
    const cardCollection = this.db.collection('cards');
    for (let index = prevIndex + 1; index < prevArray.length; index++) {
      const prevCard = prevArray[index];
      const prevRef = cardCollection.doc(prevCard.id);
      batch.update(prevRef, {position: prevCard.position - 1});
    }
    for (let index = nextIndex; index < nextArray.length; index++) {
      const nextCard = nextArray[index];
      const nextRef = cardCollection.doc(nextCard.id);
      batch.update(nextRef, {position: nextCard.position + 1});
    }
    const card = prevArray[prevIndex];
    const ref = cardCollection.doc(card.id);
    batch.update(ref, {position: nextIndex, sectionId: nextSectionId});
    batch.commit();
  }

  transferSectionToLane(prevArray: any[], nextArray: any[], prevIndex: number, nextIndex: number, nextLaneIndex: number): void {
    const batch = this.db.batch();
    const sectionCollection = this.db.collection('sections');
    for (let index = prevIndex + 1; index < prevArray.length; index++) {
      const prevSection = prevArray[index];
      const prevRef = sectionCollection.doc(prevSection.id);
      batch.update(prevRef, {position: prevSection.position - 1});
    }
    for (let index = nextIndex; index < nextArray.length; index++) {
      const nextSection = nextArray[index];
      const nextRef = sectionCollection.doc(nextSection.id);
      batch.update(nextRef, {position: nextSection.position + 1});
    }
    const section = prevArray[prevIndex];
    const ref = sectionCollection.doc(section.id);
    batch.update(ref, {position: nextIndex, lane: nextLaneIndex});
    batch.commit();
  }

  updateSectionTitle(section: Section): void {
    this.db.collection('sections').doc(section.id).set({title: section.title}, { merge: true});
  }

  updateComment(comment: Comment): void {
    this.db.collection('comments').doc(comment.id).set({content: comment.content}, { merge: true });
  }

  updateCard(card: Card): void {
    this.db.collection('cards').doc(card.id).update({text: card.text});
  }

  updateNomination(nom: Nomination): void {
    this.db.collection('nominations').doc(nom.id).update({text: nom.text});
  }

  updateBoard(board: Board): void {
    this.db.collection('boards').doc(board.id).update({name: board.name});
  }

  updateCollection(collection: Collection): void {
    this.db.collection('collections').doc(collection.id).update({name: collection.name});
  }

  initPage(): Boolean {
    return true;
  }
}
