import { Component, OnInit } from '@angular/core';
import firebase from "firebase/app";
import 'firebase/auth';
import { FirebaseService } from "../services/firebase.service";
import { ActivatedRoute, Router, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    if (this.firebaseService.initPage()) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.firebaseService.setUserEmail(user.email);
          this.firebaseService.setUserId(user.uid);
          this.firebaseService.userGifTagTrue();
          this.router.navigate(['/home'], { relativeTo: this.route });
        } else {
          this.router.navigate(['/login'], { relativeTo: this.route });
        }
      });
    }
  }

  ngOnInit(): void {
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {

      let email = window.localStorage.getItem('emailForSignIn');

      if (!email) {

        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');

      }

      firebase.auth().signInWithEmailLink(email, window.location.href)
      .then( async (result) => {

        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');

      })
      .catch((error) => {
        alert('Error: ' + error);
        this.router.navigate(['/login'], { relativeTo: this.route });
      });
    }
  }
}
