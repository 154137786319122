<div class="example-container">
    <div
      cdkDropList
      id="lane0"
      [cdkDropListData]="laneMap[0]"
      [cdkDropListConnectedTo]="['lane1', 'lane2', 'lane3']"
      class="example-list"
      (cdkDropListDropped)="dropSection($event)">
        <div *ngFor="let section of laneMap[0]" cdkDrag class="section">
            <app-section
                *ngIf="section.type === 'Standard' || section.type === 'Action Items' || section.type === 'Nominations'"
                [section]="section"
                [cards]="sectionMap[section.id]"
                [comments]="commentMap"
                [votes]="voteMap"
                [sectionConnections]="section.type === 'Standard' ? sectionConnectionMap[section.id] : ''"
                [headerColor]="laneColors[0]">
            </app-section>
            <app-section-past-action-items
                *ngIf="section.type === 'Past Action Items'"
                [boards]="sectionMap[section.id]"
                [section]="section"
                [headerColor]="laneColors[0]">
            </app-section-past-action-items>
            <app-section-poll
                *ngIf="section.type === 'Polls'"
                [polls]="pollMap[section.id]"
                [options]="optionMap[section.id]"
                [entryMap]="entryMap[section.id]"
                [section]="section"
                [headerColor]="laneColors[0]">
            </app-section-poll>
        </div>
    </div>
    <div class="add-section-button">
        <button
            mat-raised-button color="primary"
            aria-label="Add section"
            (click)="handleCreateSection(0)">
            <label class="addSectionLabel">Add Section</label>
          <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
<div class="example-container">
    <div
      cdkDropList
      id="lane1"
      [cdkDropListData]="laneMap[1]"
      [cdkDropListConnectedTo]="['lane0', 'lane2', 'lane3']"
      class="example-list"
      (cdkDropListDropped)="dropSection($event)">
        <div *ngFor="let section of laneMap[1]" cdkDrag class="section">
            <app-section
            *ngIf="section.type === 'Standard' || section.type === 'Action Items' || section.type === 'Nominations'"
                [section]="section"
                [cards]="sectionMap[section.id]"
                [comments]="commentMap"
                [votes]="voteMap"
                [sectionConnections]="section.type === 'Standard' ? sectionConnectionMap[section.id] : ''"
                [headerColor]="laneColors[1]">
            </app-section>
            <app-section-past-action-items
                *ngIf="section.type === 'Past Action Items'"
                [boards]="sectionMap[section.id]"
                [section]="section"
                [headerColor]="laneColors[1]">
            </app-section-past-action-items>
            <app-section-poll
                *ngIf="section.type === 'Polls'"
                [polls]="pollMap[section.id]"
                [options]="optionMap[section.id]"
                [entryMap]="entryMap[section.id]"
                [section]="section"
                [headerColor]="laneColors[1]">
            </app-section-poll>
        </div>
    </div>
    <div class="add-section-button">
        <button
            mat-raised-button color="primary"
            aria-label="Add section"
            (click)="handleCreateSection(1)">
            <label class="addSectionLabel">Add Section</label>
          <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
<div class="example-container">
    <div
      cdkDropList
      id="lane2"
      [cdkDropListData]="laneMap[2]"
      [cdkDropListConnectedTo]="['lane0', 'lane1', 'lane3']"
      class="example-list"
      (cdkDropListDropped)="dropSection($event)">
        <div *ngFor="let section of laneMap[2]" cdkDrag class="section">
            <app-section
            *ngIf="section.type === 'Standard' || section.type === 'Action Items' || section.type === 'Nominations'"
                [section]="section"
                [cards]="sectionMap[section.id]"
                [comments]="commentMap"
                [votes]="voteMap"
                [sectionConnections]="section.type === 'Standard' ? sectionConnectionMap[section.id] : ''"
                [headerColor]="laneColors[2]">
            </app-section>
            <app-section-past-action-items
                *ngIf="section.type === 'Past Action Items'"
                [boards]="sectionMap[section.id]"
                [section]="section"
                [headerColor]="laneColors[2]">
            </app-section-past-action-items>
            <app-section-poll
                *ngIf="section.type === 'Polls'"
                [polls]="pollMap[section.id]"
                [options]="optionMap[section.id]"
                [entryMap]="entryMap[section.id]"
                [section]="section"
                [headerColor]="laneColors[2]">
            </app-section-poll>
        </div>
    </div>
    <div class="add-section-button">
        <button
            mat-raised-button color="primary"
            aria-label="Add section"
            (click)="handleCreateSection(2)">
            <label class="addSectionLabel">Add Section</label>
          <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
<div class="example-container">
    <div
      cdkDropList
      id="lane3"
      [cdkDropListData]="laneMap[3]"
      [cdkDropListConnectedTo]="['lane0', 'lane1', 'lane2']"
      class="example-list"
      (cdkDropListDropped)="dropSection($event)">
        <div *ngFor="let section of laneMap[3]" cdkDrag class="section">
            <app-section
                *ngIf="section.type === 'Standard' || section.type === 'Action Items' || section.type === 'Nominations'"
                [section]="section"
                [cards]="sectionMap[section.id]"
                [comments]="commentMap"
                [votes]="voteMap"
                [sectionConnections]="section.type === 'Standard' ? sectionConnectionMap[section.id] : ''"
                [headerColor]="laneColors[3]">
            </app-section>
            <app-section-past-action-items
                *ngIf="section.type === 'Past Action Items'"
                [boards]="sectionMap[section.id]"
                [section]="section"
                [headerColor]="laneColors[3]">
            </app-section-past-action-items>
            <app-section-poll
                *ngIf="section.type === 'Polls'"
                [polls]="pollMap[section.id]"
                [options]="optionMap[section.id]"
                [entryMap]="entryMap[section.id]"
                [section]="section"
                [headerColor]="laneColors[3]">
            </app-section-poll>
        </div>
    </div>
    <div class="add-section-button">
        <button
            mat-raised-button color="primary"
            aria-label="Add section"
            (click)="handleCreateSection(3)">
            <label class="addSectionLabel">Add Section</label>
          <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
