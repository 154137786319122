<div class="example-list section-list">
	<div class="example-box" [style.background-color] = "headerColor" (contextmenu)="onContextMenu($event)">
		<h1 class="section-title" (click)="openEditModal()">
			{{section.title}}
		</h1>
	</div>
	<div class="example-list">
		<div *ngFor="let board of boards; let i = index">
			<div class="inner-box">
                <div class="board-title">
                    {{board.name}}
				</div>
                <div *ngFor="let card of board.cards; let j = index" class="item-box">
					{{card.text}}
                    <mat-checkbox [(checked)]="card.completed" (change)="completedChanged($event, card, i, j)"></mat-checkbox>
                </div>
            </div>
		</div>
	</div> 
</div>
