import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-add-collection-dialog',
	templateUrl: './add-collection-dialog.component.html',
	styleUrls: ['./add-collection-dialog.component.css']
})
export class AddCollectionDialogComponent implements OnInit {

	collectionForm: FormGroup = new FormGroup({
		collectionName: new FormControl('', Validators.required)
	});


	constructor(
		firebaseService: FirebaseService,
		@Inject(MAT_DIALOG_DATA)
		public data: any
	) {
	}

	ngOnInit(): void {
	}

}