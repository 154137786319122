<mat-expansion-panel [togglePosition]= "'before'" *ngIf="actionItems">
	<mat-expansion-panel-header>
		<mat-panel-title>
			{{collection.name}}
		</mat-panel-title>
	</mat-expansion-panel-header>
	<ng-container *ngIf="hasCollections" class="boardItem">
		<app-profile-action-items *ngFor="let collection of collections" [collection]="collection"></app-profile-action-items>
	</ng-container>
	
	<ng-container *ngFor="let actionItem of actionItems">
        <mat-expansion-panel [togglePosition]="'before'">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ actionItem.name }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container>
                <mat-list>
                    <mat-list-item *ngFor="let card of actionItem.cards">
                        <mat-icon matListIcon class="check">check_circle</mat-icon>
                        {{ card.text }}
                    </mat-list-item>
                </mat-list>
            </ng-container>
        </mat-expansion-panel>
	</ng-container>
</mat-expansion-panel>