<h1 mat-dialog-title>Create New Board</h1>
<div mat-dialog-content [formGroup]="boardForm" class="modal-content">
    <mat-form-field>
        <mat-label>Board Name</mat-label>
        <input matInput type="text" placeholder="Board Name" formControlName="boardName">
    </mat-form-field>
    <br/>
    <mat-form-field>
      <mat-label>Select Template</mat-label>
      <mat-select formControlName="templateName">
        <mat-option [value]="template" *ngFor="let template of templates">{{template.name}}</mat-option>
      </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="boardForm.value" [disabled]="!boardForm.valid">Create</button>
</div>