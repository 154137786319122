<h1 mat-dialog-title>Add Card</h1>
<div mat-dialog-content [formGroup]="cardForm" class="modal-content">
    <mat-form-field>
        <mat-label>Card Text</mat-label>
        <textarea matInput placeholder="Enter card text" formControlName="cardText"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="cardForm.value" [disabled]="!cardForm.valid">Add</button>
</div>