import { Card } from "./card.interface";

export interface Section {
    id: string;
    boardId: string;
    title: string;
    position: number;
    lane: number;
    type: SectionType;
}

export enum SectionType {
    Standard = 'Standard',
    ActionItem = 'Action Items',
    PastActionItem = 'Past Action Items',
    Nomination = 'Nominations',
    Poll = 'Polls'
}

export enum NominationType {
    Community = 'Community',
    Courage = 'Courage',
    Craftsmanship = 'Craftsmanship',
    Fun = 'Fun'
}
