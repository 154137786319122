// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyC0__HQNZ-BQWCfsFumhy-A9K3I71JdUoE',
    authDomain: 'nretrospective.firebaseapp.com',
    databaseURL: 'https://nretrospective.firebaseio.com',
    projectId: 'nretrospective',
    storageBucket: 'nretrospective.appspot.com',
    messagingSenderId: '823070101971',
    appId: '1:823070101971:web:6f9fc9d1e2fb7be338df26'
  },
  actionCodeSettings: {
    // URL you want to redirect back to. The domain (www.example.com) for this

    // Needs to be changed for production
    // https://...

    // URL must be whitelisted in the Firebase Console.
    url: 'https://nretrospective.web.app/auth',
    // This must be true.
    handleCodeInApp: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
