import { Component, OnInit, Input } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { Poll } from '../interfaces/poll.interface';
import { PollEntry } from '../interfaces/pollEntry.interface';
import { MatDialog } from '@angular/material/dialog';
import { AddPollCardDialogComponent } from '../section/add-poll-card-dialog/add-poll-card-dialog.component';
import { EditSectionDialogComponent } from '../section/edit-section-dialog/edit-section-dialog.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'app-section-poll',
  templateUrl: './section-poll.component.html',
  styleUrls: ['./section-poll.component.css']
})
export class SectionPollComponent implements OnInit {
  @Input() section;
  @Input() headerColor;
  @Input() polls;
  @Input() options;
  @Input() entryMap;

  selectionMap = {};
  passedMap = {};

  constructor(
    public firebaseService: FirebaseService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  getHasVoted(pollId: string): boolean {
    if (this.passedMap[pollId] === true) {
      return true;
    }
    const userId = this.firebaseService.getUserId();
    if (this.entryMap[pollId] === undefined) {
      return true;
    }
    for (const optionKey of Object.keys(this.entryMap[pollId])) {
      if (this.entryMap[pollId][optionKey]) {
        for (const entry of Object.values(this.entryMap[pollId][optionKey])) {
          if (entry['userId'] === userId) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getOptionPercentage(pollId, optionId): number {
    let total = 0;
    for (const option of this.options[pollId]) {
      if (this.entryMap[pollId][option.id] !== undefined) {
        total += this.entryMap[pollId][option.id].length;
      }
    }
    if (total === 0) {
      return 0;
    }
    if (this.entryMap[pollId][optionId] === undefined) {
      return 0;
    }
    const current = this.entryMap[pollId][optionId].length;
    return current / total * 100;
  }

  getOptionAmount(pollId, optionId): number {
    if (this.entryMap[pollId][optionId] === undefined) {
      return 0;
    }
    return this.entryMap[pollId][optionId].length;
  }

  vote(event, poll): void {
    event.stopPropagation();
    if (this.selectionMap[poll.id] === undefined) {
      return;
    }
    const entry: PollEntry = {
      userId: this.firebaseService.getUserId(),
      optionId: this.selectionMap[poll.id].id
    };
    this.firebaseService.putPollEntry(entry, poll);
  }

  removeVote(event, poll): void {
    event.stopPropagation();
    this.firebaseService.deletePollEntry(poll);
    this.passedMap[poll.id] = false;
    this.selectionMap[poll.id] = undefined;
  }

  voteChanged(event, poll): void {
    this.selectionMap[poll.id] = event.value;
  }

  pass(event, poll): void {
    event.stopPropagation();
    this.passedMap[poll.id] = true;
  }

  handleAddCard(): void {
    this.dialog.open(AddPollCardDialogComponent)
    .afterClosed().subscribe(result => {
        const options = result.actionItems.map(item => item.item);
        const title = result.title;
        const showVotes = result.showVotes;
        const poll: Poll = {
          id: this.firebaseService.getUserId(),
          sectionId: this.section.id,
          position: this.polls ? this.polls.length : 0,
          options,
          title,
          userId: this.firebaseService.getUserId(),
          showVotes
        };
        this.firebaseService.putPoll(poll, () => {});
    });
  }

  getCanEdit(poll): boolean {
    return poll.userId === this.firebaseService.getUserId();
  }

  editClicked(poll): void {
    if (!this.getHasVoted(poll.id) || !this.getCanEdit(poll)) {
      return;
    }
    this.dialog.open(AddPollCardDialogComponent, {data: {poll, options: this.options[poll.id]}})
    .afterClosed().subscribe(result => {
      if (result === '') {
        return;
      } else if (result === 'delete') {
        this.firebaseService.deletePoll(poll);
        return;
      }
      const incoming = result.actionItems;

      this.firebaseService.deletePoll(poll);
      const newPoll: Poll = {
        id: '',
        userId: this.firebaseService.getUserId(),
        sectionId: poll.sectionId,
        position: poll.position,
        options: incoming.map(item => item.item),
        title: poll.title,
        showVotes: result.showVotes
      };
      this.firebaseService.putPoll(newPoll, () => {});
    });
  }

  openEditModal(): void {
		this.dialog.open(EditSectionDialogComponent)
		.afterClosed().subscribe(result => {
			if (result) {
				if (result.sectionName) {
					this.section.title = result.sectionName;
					this.firebaseService.updateSectionTitle(this.section);
				}
				if (result.deleteSection) {
					const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
						data: { context: 'section'}
					  });
				  
					  dialogRef.afterClosed().subscribe(result => {
						if (result) {
							this.firebaseService.deleteSection(this.section);
						}
					  });
				}
			}
		});
  }
  
	onContextMenu(event: MouseEvent) {
		event.preventDefault();
	}
}
