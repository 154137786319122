<div class="loginBackground">
    <mat-card class="card">
        <mat-card-title class="loginHeader">
            <p>Log in to</p>
            <img src="../../assets/images/logoBlack.png" alt="logo" id="logo">
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="loginRow">
                    <mat-form-field appearance="outline" class="loginField">
                        <mat-label>Email</mat-label>
                        <input type="text" matInput placeholder="Please enter your nCino email here" formControlName="username">
                    </mat-form-field>
                </div>
                <div *ngIf="error" class="loginError">
                    {{ errorMessage }}
                </div>
                <div class="buttonRow">
                    <button type="submit" id="loginButton" mat-button>Log in</button>
                    <!-- can add this to button if we want to have it disabled until data is entered: [disabled]="!profileForm.valid"-->
                </div>
            </form>
        </mat-card-content>
    </mat-card>
    <footer>Created by nCino for nCino</footer>
</div>
