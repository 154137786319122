import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TenorService } from '../services/tenor.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tenor-search',
  templateUrl: './tenor-search.component.html',
  styleUrls: ['./tenor-search.component.css']
})
export class TenorSearchComponent {
  results = [];
  isSearching = false;
  gifForm: FormGroup = new FormGroup({
    gif: new FormControl('')
  });
  searchTerm;
  next;

  constructor(public tenorService: TenorService, public dialogRef: MatDialogRef<TenorSearchComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async searchGif(searchTerm, forward: boolean): Promise<any> {
    this.results = [];
    if (searchTerm.gif !== this.searchTerm) {
      this.next = null;
    }
    this.isSearching = true;
    if (!forward) {
      this.next = this.next - 20;
    }
    const searchResult = await this.tenorService.searchGif(searchTerm.gif, this.next);
    this.results = searchResult.results;
    this.next = searchResult.next;
    this.isSearching = false;
    this.searchTerm = searchTerm.gif;
    return;
  }
}
