<mat-dialog-content>
    <div class="spaced">
        <form [formGroup]="gifForm" (ngSubmit)="searchGif(gifForm.value, true)">
            <mat-form-field>
                <input matInput placeholder="Search Tenor" formControlName="gif">
            </mat-form-field>
            <button mat-button type="submit"><mat-icon>search</mat-icon></button>
        </form>
        <button mat-button (click)="onNoClick()"><mat-icon>close</mat-icon></button>
    </div>
    <div *ngIf="isSearching" class="spinner">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="results.length === 0 && !isSearching">
        <mat-dialog-content class="empty-state">
            <mat-icon>image_search</mat-icon>
            <h4>Use the search bar above to find some GIFs!</h4>
        </mat-dialog-content>
    </div>
    <div *ngIf="results.length > 0">
        <h2>Results for {{searchTerm}}:</h2>
        <mat-dialog-content>
            <mat-grid-list cols="4">
                <mat-grid-tile *ngFor="let gif of results" class="gif-option">
                    <img src={{gif.media[0].nanogif.url}} [mat-dialog-close]="gif">
                </mat-grid-tile>
            </mat-grid-list>
        </mat-dialog-content>
        <mat-dialog-content>
            <button *ngIf="next > 10" mat-button color="primary" (click)="searchGif(gifForm.value, false)">Back</button>
            <button *ngIf="results.length >= 10" mat-button color="primary" (click)="searchGif(gifForm.value, true)">Next</button>
        </mat-dialog-content>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <img src="../../assets/images/PB_tenor_logo_grey_horizontal.png" class="attribution" alt="Powered by Tenor">
</mat-dialog-actions>
