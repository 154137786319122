<h1 mat-dialog-title>Add Card</h1>
<div mat-dialog-content [formGroup]="cardForm" class="modal-content">
    <mat-form-field>
        <mat-label>Nomination Type</mat-label>
        <mat-select formControlName="nominationType">
          <mat-option [value]="type" *ngFor="let type of nominationTypes">
            <div style="float: left; margin-right: 15px;">
              <img src={{getIconPath(type)}} style="float:left;" inline="true" id="icon" width="30" height="30">
            </div>
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field>
        <mat-label>Nomination Reason</mat-label>
        <textarea matInput placeholder="Enter nomination reason" formControlName="cardAction"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="cardForm.value" [disabled]="!cardForm.valid">Add</button>
</div>