import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { BoardComponent } from './board/board.component';
import { LoginComponent } from './login/login.component';
import { SectionComponent } from './section/section.component';
import { ProfileComponent } from './profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { CardStandardComponent } from './card-standard/card-standard.component';
import { CommentComponent } from './comment/comment.component';
import { CollectionComponent } from './collection/collection.component';
import { AuthComponent } from './auth/auth.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CollectionBoardComponent } from './collection/collection-board/collection-board.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddSectionDialogComponent } from './board/add-section-dialog/add-section-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { CardStandardDialogComponent } from './card-standard-dialog/card-standard-dialog.component';
import { TenorSearchComponent } from './tenor-search/tenor-search.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AddBoardDialogComponent } from './profile/add-board-dialog/add-board-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SectionPollComponent } from './section-poll/section-poll.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SectionPastActionItemsComponent } from './section-past-action-items/section-past-action-items.component';
import { AddTextCardDialogComponent } from './section/add-text-card-dialog/add-text-card-dialog.component';
import { AddPollCardDialogComponent } from './section/add-poll-card-dialog/add-poll-card-dialog.component';
import { AddActionCardDialogComponent } from './section/add-action-card-dialog/add-action-card-dialog.component';
import { AddNominationCardDialogComponent } from './section/add-nomination-card-dialog/add-nomination-card-dialog.component';
import { EditSectionDialogComponent } from './section/edit-section-dialog/edit-section-dialog.component';
import { AddCollectionDialogComponent } from './profile/add-collection-dialog/add-collection-dialog.component';
import { CardNominationComponent } from './card-nomination/card-nomination.component';
import { ProfileActionItemsComponent } from './profile-action-items/profile-action-items.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DeleteAccountComponent } from './profile/delete-account/delete-account.component';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { AddTemplateDialogComponent } from './navigator/add-template-dialog/add-template-dialog.component';
import { EditBoardDialogComponent } from './navigator/edit-board-dialog/edit-board-dialog.component';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  declarations: [
    AppComponent,
    BoardComponent,
    LoginComponent,
    ProfileComponent,
    PageNotFoundComponent,
    NavigatorComponent,
    SectionComponent,
    AddSectionDialogComponent,
    CardStandardComponent,
    CommentComponent,
    CardStandardDialogComponent,
    AuthComponent,
    CollectionComponent,
    CollectionBoardComponent,
    TenorSearchComponent,
    AddBoardDialogComponent,
    SectionPollComponent,
    SectionPastActionItemsComponent,
    AddTextCardDialogComponent,
    AddPollCardDialogComponent,
    AddActionCardDialogComponent,
    AddNominationCardDialogComponent,
    EditSectionDialogComponent,
    AddCollectionDialogComponent,
    CardNominationComponent,
    ProfileActionItemsComponent,
    DeleteAccountComponent,
    DeleteConfirmationDialogComponent,
    AddTemplateDialogComponent,
    EditBoardDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    DragDropModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatSelectModule,
    MatListModule,
    MatToolbarModule,
    MatExpansionModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    ClipboardModule,
    MatSlideToggleModule,
    FormsModule,
    MatRadioModule,
    MatProgressBarModule,
    MatTabsModule,
    MatDividerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
