import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-section-dialog',
  templateUrl: './edit-section-dialog.component.html',
  styleUrls: ['./edit-section-dialog.component.css']
})
export class EditSectionDialogComponent implements OnInit {

  sectionForm: FormGroup = new FormGroup({
    sectionName: new FormControl('', Validators.required),
  });

  constructor() { }

  ngOnInit(): void {
  }

}
