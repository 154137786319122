<mat-expansion-panel [togglePosition]= "'before'" [expanded]="shouldExpand">
	<mat-expansion-panel-header>
		<mat-panel-title>
			{{collection.name}}
		</mat-panel-title>
	</mat-expansion-panel-header>
	<ng-container *ngIf="hasCollections" class="boardItem">
		<app-collection *ngFor="let collection of collections" [collection]="collection"></app-collection>
	</ng-container>
	<ng-container *ngFor="let board of boards">
		<app-collection-board 
			[boardName]="board.name"
			[boardId]="board.id">
		</app-collection-board>
	</ng-container>
	<mat-action-row class="spaced">
		<div>
			<button mat-button aria-label="Delete Collection" color="warn" id="btn-add-board" alt="add board" (click)="handleDeleteCollection()">
				<label>Delete Collection</label>
				<mat-icon> delete </mat-icon>
			</button>
			<button mat-button aria-label="Edit Collection" color="basic" id="btn-add-board" alt="add board" (click)="handleEditCollection()">
				<label>Edit Collection</label>
				<mat-icon> edit </mat-icon>
			</button>
		</div>
		<mat-divider [vertical]="true"></mat-divider>
		<div>
			<button mat-button aria-label="Add Collection" color="primary" id="btn-add-collection" alt="add collection" (click)="handleCreateSubcollection()">
				<label>Add Subcollection </label>
				<mat-icon> library_add </mat-icon>
			</button>
			<button mat-button aria-label="Add Board" color="primary" id="btn-add-board" alt="add board" (click)="handleCreateBoard()">
				<label>Add Board </label>
				<mat-icon> addchart </mat-icon>
			</button>
		</div>
	</mat-action-row>
</mat-expansion-panel>