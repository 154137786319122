import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-board-dialog',
  templateUrl: './add-board-dialog.component.html',
  styleUrls: ['./add-board-dialog.component.css']
})
export class AddBoardDialogComponent implements OnInit {

  boardForm: FormGroup = new FormGroup({
    boardName: new FormControl('', Validators.required),
    templateName: new FormControl('')
  });

  templates = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any
    ) {
      this.templates = this.data.templatesList;
    }

  ngOnInit(): void {
  }

}
