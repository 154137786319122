<div class="example-list section-list">
	<div class="example-box" [style.background-color] = "headerColor" (contextmenu)="onContextMenu($event)">
		<h1 class="section-title" (click)="openEditModal()">
			{{section.title}}
		</h1>
		<button mat-icon-button matSuffix (click)="handleAddCard()" [attr.aria-label]="'Add Card'">
			<mat-icon class="icon-add click-icon" >add</mat-icon>
		</button>
	</div>
	<div cdkDropList class="example-list"
		id={{section.id}}
		[cdkDropListData]="cards"
		(cdkDropListDropped)="dropCard($event)"
		[cdkDropListConnectedTo]="sectionConnections">
		<div *ngIf="section.type == sectionType.Nomination">
			<div *ngFor="let card of cards" cdkDrag class="card-cdkDrag">
				<app-card-nomination
					[card]="card"
					[comments]="comments[card.id]"
					[votes]="votes[card.id]">
				</app-card-nomination>
			</div>
		</div>
		<div *ngIf="section.type != sectionType.Nomination">
			<div *ngFor="let card of cards" cdkDrag class="card-cdkDrag">
				<app-card-standard
					[card]="card"
					[comments]="comments[card.id]"
					[votes]="votes[card.id]">
				</app-card-standard>
			</div>
		</div>
	</div>
</div>
<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
  *ngIf="section.type == sectionType.Nomination"
></div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item [cdkCopyToClipboard]="getFormattedKudos()" (click)="onContextMenuCopy()">Copy</button>
  </ng-template>
</mat-menu>
