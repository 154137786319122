<h1 mat-dialog-title>Edit/Delete Section</h1>
<div mat-dialog-content [formGroup]="sectionForm" class="modal-content">
    <mat-form-field>
        <mat-label>Section Name</mat-label>
        <input matInput type="text" placeholder="Section Name" formControlName="sectionName">
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="{deleteSection: true}" color="warn">Delete</button>
  <button mat-button [mat-dialog-close]="sectionForm.value" [disabled]="!sectionForm.valid">Save</button>
</div>