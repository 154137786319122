import { group } from '@angular/animations';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { noWhitespace } from '../../validators/no-whitespace.validator';

@Component({
  selector: 'app-add-poll-card-dialog',
  templateUrl: './add-poll-card-dialog.component.html',
  styleUrls: ['./add-poll-card-dialog.component.css']
})
export class AddPollCardDialogComponent implements OnInit {

  cardForm: FormGroup;
  isEdit;
  showVotes: boolean;

  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data !== null) {
      const groupArray = data.options.map(option => this.fb.group({item: [option.title, [Validators.required, noWhitespace]]}));
      this.cardForm = this.fb.group({
        title: [data.poll.title, [Validators.required, noWhitespace]],
        actionItems: this.fb.array(groupArray),
        showVotes: data.poll.showVotes
      });
      this.isEdit = true;
    } else {
      this.isEdit = false;
      this.showVotes = false;
    }
  }

  ngOnInit(): void {
    if (!this.isEdit) {
      this.cardForm = this.fb.group({
        title: ['', [Validators.required, noWhitespace]],
        actionItems: this.fb.array([this.fb.group({item: ['', [Validators.required, noWhitespace]]})]),
        showVotes: false
      });
    }
  }

  get actionItems(): FormArray {
    return this.cardForm.get('actionItems') as FormArray;
  }

  addActionItem(): void {
    this.actionItems.push(this.fb.group({item: ['', [Validators.required, noWhitespace]]}));
  }

  deleteActionItem(index): void {
    this.actionItems.removeAt(index);
  }
}
