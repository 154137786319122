import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-board-dialog',
  templateUrl: './edit-board-dialog.component.html',
  styleUrls: ['./edit-board-dialog.component.css']
})
export class EditBoardDialogComponent implements OnInit {

  context: string;

  boardForm: FormGroup = new FormGroup({
    boardName: new FormControl('', Validators.required)
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.boardForm.controls.boardName.setValue(data.currentBoard);
    this.context = data.context;
  }

  ngOnInit(): void {
  }

}
