<h1 mat-dialog-title>Create Template</h1>
<div mat-dialog-content [formGroup]="templateForm" class="modal-content">
    <mat-form-field>
        <mat-label>Template Name</mat-label>
        <input matInput type="text" placeholder="Template Name" formControlName="templateName">
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="templateForm.value" [disabled]="!templateForm.valid">Create</button>
</div>