<mat-list-item *ngIf="!comment.gif" [class]="getCommentColor()">
    <div *ngIf="!editMode" class="comment-color spaced">
        <div class="comment-content">{{comment.content}}</div>
        <div>
            <mat-icon *ngIf="editable" (click)="toggleEdit()" class="click-icon" inline="true">edit</mat-icon>
            <mat-icon *ngIf="editable" (click)="deleteComment()" class="click-icon" inline="true">delete</mat-icon>
        </div>
    </div>
    <div *ngIf="editMode" class="commentEditDiv">
        <form [formGroup]="commentForm" (ngSubmit)="onSubmit(commentForm.value)" class="editCommentForm">
            <mat-form-field>
                <textarea matInput placeholder={{comment.content}} value={{comment.content}} formControlName="comment"></textarea>
            </mat-form-field>
            <button mat-button type="submit"><mat-icon class="click-icon" inline="true">done</mat-icon></button>
        </form>
    </div>
</mat-list-item>
<mat-list-item *ngIf="comment.gif" [class]="getCommentColor()" style="height: auto;">
    <div *ngIf="showGifs">
        <img src={{comment.gif}} class="gif">
        <mat-icon *ngIf="editable" (click)="deleteComment()" inline="true" class="comment-color click-icon">delete</mat-icon>
    </div>
    <div *ngIf="!showGifs" class="comment-color">
        <i>Gifs are disabled in the user preferences.</i>
    </div>
</mat-list-item>
