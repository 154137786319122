import { Component, OnInit } from '@angular/core';
import { NominationType } from 'src/app/interfaces/section.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { noWhitespace } from '../../validators/no-whitespace.validator';

@Component({
  selector: 'app-add-nomination-card-dialog',
  templateUrl: './add-nomination-card-dialog.component.html',
  styleUrls: ['./add-nomination-card-dialog.component.css']
})
export class AddNominationCardDialogComponent implements OnInit {

  nominationTypes = [
    NominationType.Community,
    NominationType.Craftsmanship,
    NominationType.Courage,
    NominationType.Fun
  ];

  cardForm: FormGroup = new FormGroup({
    cardAction: new FormControl('', [Validators.required, noWhitespace]),
    nominationType: new FormControl('', Validators.required),
  });

  constructor() { }

  ngOnInit(): void {
  }

  getIconPath(type: NominationType): string {
    switch (type) {
      case NominationType.Craftsmanship:
        return "../../assets/images/craftsmanship.png";
      case NominationType.Courage:
        return "../../assets/images/courage.png"
      case NominationType.Community:
        return "../../assets/images/community.png";
      default:
        return "../../assets/images/fun.png";
    }
  }

}
