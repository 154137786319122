<div class="title-bar">
    <h1 mat-dialog-title>{{!isEdit ? 'Add' : 'Edit'}} Poll</h1>
    <div *ngIf="isEdit">
        <button mat-icon-button matSuffix [mat-dialog-close]="'delete'" [attr.aria-label]="'Delete Poll'">
            <mat-icon matSuffix>delete</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-content [formGroup]="cardForm" class="modal-content">
    <mat-form-field>
        <input matInput type="text" placeholder="Poll Title" formControlName="title">
    </mat-form-field>
    <h2>Poll Options:</h2>
    
    <div formArrayName="actionItems" class="pollOptionsContainer">
        <mat-form-field *ngFor="let item of actionItems.controls; let itemIndex=index" [formGroupName]="itemIndex">
            <mat-label>Option {{itemIndex + 1}}</mat-label>
            <input matInput type="text" placeholder="Option {{itemIndex + 1}}" formControlName="item">
            <button mat-icon-button matSuffix (click)="deleteActionItem(itemIndex)" [attr.aria-label]="'Delete Option'">
                <mat-icon matSuffix>delete</mat-icon>
            </button>
        </mat-form-field>
        <button mat-button type="button" (click)="addActionItem()">Add Poll Option</button>
    </div>
    <mat-checkbox formControlName="showVotes">Show Vote Numbers</mat-checkbox>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="cardForm.value" [disabled]="!cardForm.valid">{{!isEdit ? 'Add' : 'Save'}}</button>
</div>