import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SectionType } from '../../interfaces/section.interface';

@Component({
  selector: 'app-add-section-dialog',
  templateUrl: './add-section-dialog.component.html',
  styleUrls: ['./add-section-dialog.component.css']
})
export class AddSectionDialogComponent implements OnInit {

  sectionForm: FormGroup = new FormGroup({
    sectionName: new FormControl('', Validators.required),
    sectionType: new FormControl('', Validators.required),
  });

  sectionTypes = [
    SectionType.Standard,
    SectionType.ActionItem,
    SectionType.Nomination,
    SectionType.PastActionItem,
    SectionType.Poll
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
