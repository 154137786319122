import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenorService {
  private tenorKey = 'KGEBWQPM0W6S';

  constructor() { }

  async searchGif(searchTerm, offset?: number): Promise<any> {
    const limit = 10;

    let searchUrl = 'https://api.tenor.com/v1/search?q=' + searchTerm + '&key=' +
    this.tenorKey + '&limit=' + limit + '&contentfilter=high';
    if (offset) {
      searchUrl += '&pos=' + offset;
    }

    return JSON.parse(await this.httpGetAsync(searchUrl));
  }

  private async httpGetAsync(searchUrl): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState !== 4) { return; }
        if (xmlHttp.status >= 200 && xmlHttp.status < 300) {
          resolve(xmlHttp.responseText);
        } else {
          reject(xmlHttp.statusText);
        }
      };
      xmlHttp.open('GET', searchUrl, true);
      xmlHttp.send(null);
    });
  }
}
