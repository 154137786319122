<h1 mat-dialog-title>Create New Collection</h1>
<div mat-dialog-content [formGroup]="collectionForm" class="modal-content">
	<mat-form-field>
		<mat-label>Collection Name</mat-label>
		<input matInput type="text" placeholder="Collection Name" formControlName="collectionName">
	</mat-form-field>
</div>
<div mat-dialog-actions>
	<button mat-button mat-dialog-close>Cancel</button>
	<button mat-button [mat-dialog-close]="collectionForm.value" [disabled]="!collectionForm.valid">Create</button>
</div>