<mat-card>
	<mat-card-content
		(click)="openCardModal()"
		class="wrap">{{card.text}}
		<div style="float: left; margin-right: 15px;">
			<img src={{getIconPath()}} style="float:left;" inline="true" id="icon" width="50" height="50">
		</div>
	</mat-card-content>
    <mat-card-content>
		<div class>
			<p>{{votes ? votes.length : null}} </p> <mat-icon class="icon click-icon plusOne" inline="true" matPrefix (click)="vote()">plus_one</mat-icon>
			<p>{{comments ? comments.length : null}}</p> <mat-icon class="icon click-icon comment" inline="true" matPrefix (click)="openCardModal()">comment</mat-icon>
		</div>
    </mat-card-content>
</mat-card>
