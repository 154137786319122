<mat-dialog-content>
    <div *ngIf="!editMode" class="spaced">
        <div>
            <div *ngIf="icon" style="float: left; margin-right: 8px;">
                <img src={{icon}} style="float:left;" inline="true" id="icon" width="30" height="30">
            </div>
            {{card.text}}
        </div>
        <div *ngIf="canEdit" >
            <mat-icon class="click-icon" inline="true" (click)="toggleEdit()">edit</mat-icon>
            <mat-icon class="click-icon" inline="true" (click)="deleteCard()">delete</mat-icon>
        </div>
    </div>
    <div *ngIf="editMode" class="spaced">
        <form [formGroup]="cardForm" (ngSubmit)="editCard(cardForm.value)" class="card-edit">
            <mat-form-field>
                <textarea matInput placeholder={{card.text}} value={{card.text}} formControlName="card"></textarea>
            </mat-form-field>
            <button mat-button [disabled]="!cardForm.valid" type="submit"><mat-icon class="click-icon" inline="true">done</mat-icon></button>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-content class="reactions">
    <p>{{votes.length}}</p> <mat-icon class="icon click-icon plusOne" inline="true" matPrefix (click)="vote()">plus_one</mat-icon>
</mat-dialog-content>
<mat-dialog-content class="comment-list">
    <mat-list>
        <div *ngFor="let comment of comments; let i = index">
            <app-comment [comment]="comment" [index]="i" [showGifs]="showGifs" [editable]="checkEdit(i)" (deleteEvent)="deleteComment($event)" (editEvent)="editComment($event)"></app-comment>
        </div>
    </mat-list>
</mat-dialog-content>
<mat-dialog-content>
    <form [formGroup]="commentForm" (ngSubmit)="onSubmit(commentForm.value)">
        <mat-form-field>
            <textarea matInput placeholder="Leave a comment" formControlName="comment"></textarea>
        </mat-form-field>
        <button mat-button type="submit" [disabled]="!commentForm.valid">Submit</button>
        <button mat-mini-fab color="primary" type="button" (click)="openTenor()" disabled={{!showGifs}} class="gifButton"><mat-icon>gif</mat-icon></button>
    </form>
</mat-dialog-content>
