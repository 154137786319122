export interface Nomination {
    id: string;
    sectionId: string;
    position: number;
    text: string;
    userId: string;
    type: NominationType;
}

export enum NominationType {
    Community = 'Community',
    Courage = 'Courage',
    Craftsmanship = 'Craftsmanship',
    Fun = 'Fun'
}
