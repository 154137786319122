<div class="example-list section-list">
	<div class="example-box" [style.background-color] = "headerColor" (contextmenu)="onContextMenu($event)">
		<h1 class="section-title" (click)="openEditModal()">
			{{section.title}}
        </h1>
        <button mat-icon-button matSuffix (click)="handleAddCard()" [attr.aria-label]="'Add Card'">
			<mat-icon class="icon-add click-icon" >add</mat-icon>
		</button>
	</div>
	<div class="example-list">
		<div *ngFor="let poll of polls">
			<div class="inner-box" (click)="editClicked(poll)">
                <div class="board-title">
                    {{poll.title}}
                </div>
                <div *ngIf="!getHasVoted(poll.id)">
                    <mat-radio-group (change)="voteChanged($event, poll)">
                        <div *ngFor="let option of options[poll.id]" class="item-box">
                            <mat-radio-button [value]="option"></mat-radio-button>
                            <div>{{option.title}}</div>
                        </div>
                    </mat-radio-group>
                    <div class="action-bar">
                        <button mat-stroked-button (click)="vote($event, poll)" color="primary" [disabled]="selectionMap[poll.id] === undefined">Submit</button>
                        <button mat-stroked-button (click)="pass($event, poll)">Pass</button>
                    </div>
                </div>
                <div *ngIf="getHasVoted(poll.id)">
                    <div *ngFor="let option of options[poll.id]" class="result-box">
                        <div class="spaced">
                            <div class="wrap">{{option.title}}</div>
                            <div *ngIf="poll.showVotes">{{getOptionAmount(poll.id, option.id)}}</div>
                        </div>
                        <mat-progress-bar mode="determinate" [value]="getOptionPercentage(poll.id, option.id)"></mat-progress-bar>
                    </div>
                    <div class="action-bar">
                        <button mat-stroked-button (click)="removeVote($event, poll)">Vote</button>
                    </div>
                </div>
            </div>
		</div>
	</div> 
</div>
