<h1 mat-dialog-title>Rename {{context}}</h1>
<div mat-dialog-content [formGroup]="boardForm" class="modal-content">
    <mat-form-field>
        <mat-label>New Name</mat-label>
        <input matInput type="text" placeholder="New Name" formControlName="boardName">
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="boardForm.value" [disabled]="!boardForm.valid">Rename</button>
</div>